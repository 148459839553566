import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, NavLink } from "react-router-dom";
import { AliasContext } from "../../components/context/AliasContext";

export default function Footer({location}) {

    const { alias } = useContext(AliasContext)

    const isMobile = useMediaQuery({
        query: '(max-width: 1024px)',
    });
    
    const returnPathname = (path) => {
        let pathname = path.slice(1, path.length)
        return pathname.replace('-', ' ')
    }

    return (
        <footer className={`footer ${alias}`}>
            <div>
                <div className="top-footer">
                    <div className="footer-breadcrumbs">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            {location.pathname !== '/' && <li><span>{location.pathname === '/signup' ? 'Sign Up' : returnPathname(location.pathname)}</span></li>}
                        </ul>
                    </div>
                    <div className="footer-nav">
                        {
                            isMobile ?
                            <div className="mobile-nav">
                                <ul>
                                    <li><Link to="/advertisers">ADVETISERS</Link></li>
                                    <li><Link to="/publishers">PUBLISHERS</Link></li>
                                    <li><Link to="/contact">CONTACT</Link></li>
                                    <li><Link to="/opt-out">OPTOUT</Link></li>
                                    {alias === "admatic" && <li><Link to="/privacy-policy">PRIVACY POLICY</Link></li>}
                                    {alias === "admatic" && <li><Link to="/imprint">IMPRINT</Link></li>}
                                    {alias !== "admatic" && <li><Link to="https://developer.pixad.com.tr/" target="_blank">DOCUMENTS</Link></li>}
                                </ul>
                            </div>
                            :
                            <div className="navmenu">
                                <ul>
                                    <li>
                                        <div>
                                            <div>
                                                <span>SOLUTIONS</span>
                                            </div>
                                            <ul>
                                                <li><Link to="/advertisers">ADVETISERS</Link></li>
                                                <li><Link to="/publishers">PUBLISHERS</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span>ABOUT</span>
                                            </div>
                                            <ul>
                                                <li><Link to="/contact">CONTACT</Link></li>
                                                <li><Link to="/opt-out">OPTOUT</Link></li>
                                                {alias === "admatic" && <li><Link to="/privacy-policy">PRIVACY POLICY</Link></li>}
                                                {alias === "admatic" && <li><Link to="/imprint">IMPRINT</Link></li>}
                                            </ul>
                                        </div>
                                    </li>
                                    {alias !== "admatic" && 
                                    <li>
                                        <div>
                                            <div>
                                                <span>FOR DEVELOPERS</span>
                                            </div>
                                            <ul>
                                            <li><Link to={alias === 'admatic' ? "https://developer.admatic.com.tr/" : "https://developer.pixad.com.tr/"} target="_blank">DOCUMENTS</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className="bottom-footer">
                    <div>
                        <div className="footer-actions">
                            {(isMobile && alias !== "admatic") &&
                            <>
                                <Link className={`action-button-fill primary ${alias}`} target="_blank" to={alias === 'admatic' ? 'https://apps.admatic.com.tr/auth/sign-up' : 'https://apps.pixad.com.tr/auth/sign-up'}>CREATE ACCOUNT</Link>
                                <Link className="action-button-normal" to={alias === 'admatic' ? 'https://apps.admatic.com.tr/' : 'https://apps.pixad.com.tr/'} target="_blank">LOGIN</Link>
                            </>
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            <ul>
                                <li>© 2024 {alias === "admatic" ? "AdMatic" : "Pixad"}. All rights reserved.</li>
                                {alias === "pixad" && <li><Link to="/user-agreement">User Agreement</Link></li>}
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
