import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/tailwind.css';
import App from './App';
import ReactGA from 'react-ga4';

ReactGA.initialize(window.location.host.includes("admatic") || window.location.host.includes("localhost") ? 'G-4VB831D7X5' : 'G-H97Z2CF1WF');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
