import { Outlet, useLocation } from "react-router-dom";
import Footer from "./footer";
import Header from "./header";
import { useEffect, useState } from "react";

export default function Layout() {

    const [style, setStyle] = useState('bg-white border-b text-[#333]')
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === "/advertisers" || location.pathname === "/publishers") {
            setStyle('secondary')
        } else {
            setStyle('primary')
        }

        window.scrollTo(0, 0);
    }, [location.pathname])

    return (
        <>
            <Header style={style} location={location}/>
            <div className="pb-10">
                <Outlet />
            </div>
            <Footer location={location}/>
        </>
    )
}