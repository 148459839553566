export const ContactAPI = async(name, subject, email, message) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, subject, email, message })
    };

    const response = await fetch('https://api.pixad.com.tr/contact', requestOptions)
    const data = response.json()
    return data
}