import Cooperate from "../../components/cooperate";
import Coverage from "./components/coverage";
import Hero from "./components/hero";
import LatestNews from "./components/latest-news";
import Vision from "./components/vision";

export default function Home() {

    return (
        <>
            <Hero />
            <Vision />
            <LatestNews />
            <Coverage />
            <Cooperate />
        </>
    )
}