import { useContext } from "react";
import { AliasContext } from "../../components/context/AliasContext";
import Wrap from "../../components/wrap";
import { Link } from "react-router-dom";

export default function PrivacyPolicyWeb() {
  const { alias } = useContext(AliasContext);

  return (
    <section className="privacy-policy">
      <div>
        {alias === "admatic" && (
          <Wrap>
            <h3>PRIVACY POLICY</h3>
            <h4>Data protection declaration</h4>
            <p>
              As the operator of this website and the subpages linked to it, we
              as the responsible party take the protection of your personal data
              very seriously. We treat your personal data confidentially and in
              accordance with the statutory data protection regulations and this
              data protection declaration.
            </p>
            <h5>I. Name and address of the responsible party</h5>
            <p>
              The responsible party within the meaning of the General Data
              Protection Regulation and other national data protection laws{" "}
              <br />
              Admatic GmbH
              <br />
              Butzweilerhofallee 3<br />
              50829 Cologne
              <br />
              Germany
              <br />
              Represented by:
              <br />
              Managing Director: Tansel Okan
              <br />
              Commercial Register: HRB 116994
              <br />
              Registration Court: Cologne District Court
              <br />
              VAT ID
              <br />
              VAT identification number according to Section 27a of the Sales
              Tax Law:
              <br />
              DE368343824
              <br />
              Contact
              <br />
              E-mail info@admatic.de
              <br />
            </p>
            <h5>II. General information on data processing</h5>
            <h6>1. Scope of processing of personal data</h6>
            <p>
              We generally only process our users' personal data to the extent
              that this is necessary to provide a functional website and our
              content and services. Our users' personal data is usually only
              processed with the user's consent. An exception applies in cases
              where prior consent cannot be obtained for actual reasons or where
              the processing of the data is permitted by law.
            </p>
            <h6>2. Legal basis for the processing of personal data</h6>
            <p>
              If we obtain the consent of the data subject for processing
              personal data, Art. 6 (1)(a) of the EU General Data Protection
              Regulation (hereinafter "GDPR") serves as the legal basis. For the
              processing of personal data that is necessary to fulfill a
              contract to which the data subject is a party, Art. 6 (1)(b) GDPR
              serves as the legal basis. This also applies to processing
              operations that are necessary to carry out pre-contractual
              measures. If processing is necessary to protect a legitimate
              interest of our company or a third party and the interests,
              fundamental rights and freedoms of the data subject do not
              outweigh the former interest, Art. 6 (1) (f) GDPR serves as the
              legal basis for processing.
            </p>
            <h6>3. Data deletion and storage period</h6>
            <p>
              The personal data of the data subject will be deleted or blocked
              as soon as the purpose of storage no longer applies. Storage may
              also take place if this has been provided for by the European or
              national legislator in EU regulations, laws or other provisions to
              which the controller is subject. The data will also be blocked or
              deleted if a storage period prescribed by the aforementioned
              standards expires, unless there is a need to continue storing the
              data for the conclusion or fulfillment of a contract.
            </p>
            <h5>III. Provision of the website and creation of log files</h5>
            <h6>1. Description and scope of data processing</h6>
            <p>
              Every time our website is accessed, our system automatically
              records data and information from the computer system of the
              accessing computer.
              <br />
              The following data is collected:
              <br />
              Information about the browser type and version used
              <br />
              The user's operating system
              <br />
              The user's Internet service provider
              <br />
              The user's IP address
              <br />
              Date and time of access
              <br />
              Websites from which the user's system accesses our website
              <br />
              Websites accessed by the user's system via our website
              <br />
              The data is also stored in the log files of our system. This data
              is not stored together with other personal data of the user.
            </p>
            <h6>2. Legal basis for data processing</h6>
            <p>
              The legal basis for the temporary storage of data and log files is
              Art. 6 (1) (f) GDPR.
            </p>
            <h6>3. Purpose of data processing</h6>
            <p>
              The temporary storage of the IP address by the system is necessary
              to enable the website to be delivered to the user's computer. For
              this purpose, the user's IP address must be stored for the
              duration of the session. The data is stored in log files to ensure
              the functionality of the website. In addition, the data is used to
              technically optimize the website and to ensure the security of our
              information technology systems. The data is not evaluated for
              marketing purposes in this context. Our legitimate interest in
              data processing in accordance with Art. 6 (1) (f) GDPR also lies
              in these purposes.
            </p>
            <h6>4. Duration of storage</h6>
            <p>
              The data is deleted as soon as it is no longer necessary to
              achieve the purpose of collection. If the data is collected to
              provide the website, this is when the relevant session ends. If
              the data is stored in log files, this occurs after ninety days at
              the latest. Storage beyond this period is possible. In this case,
              the users' IP addresses are deleted or changed so that it is no
              longer possible to assign the calling client.
            </p>
            <h6>5. Possibility of objection and removal</h6>
            <p>
              You can object to the processing of your personal data at any time
              if there are reasons arising from your particular situation that
              speak against the data processing. Please note, however, that if
              your objection is successful, it will not be possible for us to
              continue to operate this website for you.
            </p>
            <h5>IV. Use of cookies</h5>
            <h6>1. Description and scope of data processing</h6>
            <p>
              Our website uses cookies. Cookies are text files that are stored
              in the Internet browser or by the Internet browser on the user's
              computer system. When a user visits a website, a cookie can be
              stored on the user's device. This cookie contains a characteristic
              character sequence that enables the browser to be clearly
              identified when the website is accessed again. We use cookies to
              make our website functional. Some elements of our website require
              that the device accessing the website can be identified even after
              a page change. The following data is stored and transmitted in the
              cookie: <br />
              Browser settings
              <br />
              Access path
              <br />
              IP address, if applicable
              <br />
              We also use cookies on our website that enable an analysis of the
              users' surfing behavior.
              <br />
              In this way, the following data can be stored and transmitted:
              <br />
              Search terms entered
              <br />
              Frequency of page views
              <br />
              Use of website functions
              <br />
            </p>
            <table>
              <tr>
                <th>Cookie Identifier</th>
                <th>Legal Basis</th>
                <th>Function</th>
                <th>Storage Duration</th>
              </tr>
              <tr>
                <td>uids</td>
                <td>Legitimate Interest</td>
                <td>
                  Necessary; enables personalized ads and service functionality
                </td>
                <td>90 Days (7776000 sec)</td>
              </tr>
              <tr>
                <td>ade_cookie_permission</td>
                <td>Save and communicate privacy choices</td>
                <td>Used to track user cookie consent.</td>
                <td>90 Days (7776000 sec)</td>
              </tr>
            </table>
            <h6>2. Legal basis for data processing</h6>
            <p>
              The legal basis for setting technically required cookies (type:
              necessary) is Section 25 Paragraph 2 No. 2 TTDSG. The legal basis
              for processing the personal data obtained in this way is the
              legitimate interest in accordance with Art. 6 Paragraph 1 Letter f
              GDPR. The legal basis for setting non-required cookies and
              processing the personal data obtained in this way is your consent
              in accordance with Section 25 Paragraph 1 TTDSG and Art. 6
              Paragraph 1 Letter a GDPR.
            </p>
            <h6>3. Purpose of data processing</h6>
            <p>
              The purpose of using technically necessary cookies is to enable
              users to use the website. Some basic functions of our website
              cannot be provided without the use of necessary cookies. For this
              purpose, the calling device must be recognized even after a page
              change. Analysis cookies are used to improve the quality of our
              website and its content. We learn how the website is used through
              analysis cookies and can therefore continuously optimize our
              services.
            </p>
            <h6>4. Duration of storage</h6>
            <p>
              We use so-called session cookies on this website. These are
              automatically deleted from your device at the end of your internet
              session. We also use so-called persistent cookies on this website.
              These are stored on your device. You can delete these yourself by
              clearing your browser history. Please see the table above for the
              other storage periods.
            </p>
            <h6>5. Objection and removal options</h6>
            <p>
              As a user, you have full control over the use of cookies. By
              changing the settings in your Internet browser, you can deactivate
              or restrict the storage of cookies. Cookies that have already been
              stored can be deleted at any time. This can also be done
              automatically. In addition, you can revoke your consent to the
              processing of the data collected by the cookies at any time with
              effect for the future. The legality of the processing carried out
              up to the time of revocation remains unaffected. You can revoke
              your consent here:{" "}
              <Link to="/opt-out">http://www.admatic.de/opt-out</Link> If
              cookies are deactivated for our website, it may no longer be
              possible to fully use all of the website's functions.
            </p>
            <h5>V. Registration on the website</h5>
            <h6>1. Description and scope of data processing</h6>
            <p>
              On our website, we offer users the opportunity to register by
              providing personal data. The data is entered into an input mask
              and transmitted to us and stored. The data is not passed on to
              third parties. The following data is collected as part of the
              registration process: <br />
              Name
              <br />
              Email
              <br />
              Telephone
              <br />
              number
              <br />
            </p>
            <h6>2. Legal basis for processing</h6>
            <p>
              The legal basis for the processing of personal data is Art. 6 (1)
              (b) GDPR in conjunction with Section 26 (1), (8) sentence 2 BDSG.
              According to this, the processing of data that is required in
              connection with the decision on establishing an employment
              relationship is permissible. If the data is required for legal
              proceedings after completion of the application process, data
              processing can take place on the basis of the requirements of Art.
              6 GDPR, in particular to protect legitimate interests in
              accordance with Art. 6 (1) (f) GDPR. Our interest then lies in
              asserting or defending claims. In the event that you have
              consented to your data being included and stored in the applicant
              pool, the legal basis is Art. 6 (1) (a) GDPR.
            </p>
            <h6>3. Purpose of processing</h6>
            <p>
              The personal data that you have submitted as part of your
              application will be processed exclusively for the purpose of
              selecting applicants and for carrying out, managing and processing
              the application process.
            </p>
            <h6>4. Duration of storage</h6>
            <p>
              The data will be deleted as soon as it is no longer required to
              achieve the purpose for which it was collected. In the event of a
              rejection and lack of consent to the inclusion and storage of the
              data in the applicant pool, applicants' data will be deleted 6
              months after rejection. If you have given your consent to the
              inclusion and storage of your personal data in the applicant pool,
              the data will be deleted no later than 2 years after inclusion.
            </p>
            <h6>5. Objection and possibility of revocation</h6>
            <p>
              If you have given us your consent to include and store your data
              in the applicant pool, you have the right to revoke your consent
              at any time with effect for the future. The revocation of the
              consent does not affect the legality of the processing carried out
              on the basis of the consent until the revocation. If you wish to
              object to the processing of your data after completion of the
              application process, which we base on a legitimate interest, you
              can object to this processing if there are reasons arising from
              your particular situation that speak against the data processing.
              Please use the contact details above to assert your rights.
            </p>
            <h5>VI. Contact form and email contact</h5>
            <h6>1. Description and scope of data processing</h6>
            <p>
              There is a contact form on our website that can be used to contact
              us electronically. If a user makes use of this option, the data
              entered in the input mask is transmitted to us and stored. This
              data is: <br />
              Name
              <br />
              Email address
              <br />
              Message data
              <br />
              In this context, the data is not passed on to third parties. The
              data is used exclusively to process the request.
            </p>
            <h6>2. Legal basis for data processing</h6>
            <p>
              The legal basis for processing the data transmitted when sending
              an email is Art. 6 (1) (f) GDPR. If the email contact is aimed at
              concluding a contract, the additional legal basis for processing
              is Art. 6 (1) (b) GDPR.
            </p>
            <h6>3. Purpose of data processing</h6>
            <p>
              The processing of the personal data from the input mask serves us
              solely to process the contact. If you contact us by email, this
              also constitutes the necessary legitimate interest in processing
              the data.
            </p>
            <h6>4. Duration of storage</h6>
            <p>
              The data will be deleted as soon as it is no longer required to
              achieve the purpose for which it was collected. For the personal
              data from the input mask of the contact form and those that were
              sent by email, this is the case when the user's respective request
              has been finally completed. The request is completed when it can
              be inferred from the circumstances that the matter in question has
              been finally clarified.
            </p>
            <h6>5. Possibility of objection and removal</h6>
            <p>
              If the request is based on a legitimate interest: You can object
              to the processing of your personal data at any time if there are
              reasons from your particular situation that speak against the data
              processing. Please inform us of your objection using the contact
              details above. All personal data that was saved in the course of
              contacting us will be deleted in this case. Please note, however,
              that if your objection is successful, it will not be possible for
              us to process your request any further. The request is used to
              conclude a contract: If the data is required to fulfill a contract
              or to carry out pre-contractual measures, early deletion of the
              data is only possible if there are no contractual or legal
              obligations that prevent deletion.
            </p>
            <h5>VII. Links to other websites</h5>
            <p>
              Our homepage may contain links to third-party websites. If you
              follow a link to one of these websites, please note that we cannot
              accept any responsibility or guarantee for third-party content or
              data protection conditions. Please make sure you are aware of the
              applicable data protection conditions before you transmit personal
              data to these websites.
            </p>
            <h5>VIII. Data security</h5>
            <p>
              Unfortunately, the transmission of information over the Internet
              is not completely secure, which is why we cannot guarantee the
              security of data transmitted to and via our website over the
              Internet. However, we protect our website and other systems as
              best as possible through technical and organizational measures
              against loss, destruction, access, modification or distribution of
              your data by unauthorized persons. We take precautions to ensure
              the security of your personal data. Your data is carefully
              protected against loss, destruction, falsification, manipulation
              and unauthorized access or disclosure.
            </p>
            <h5>IX. Rights of the data subject</h5>
            <p>
              If your personal data is processed, you are a data subject within
              the meaning of the GDPR and you have the following rights
              vis-à-vis the controller:
            </p>
            <h6>1. Right to information</h6>
            <p>
              You can request confirmation from the responsible party as to
              whether personal data concerning you is being processed by us. If
              such processing is taking place, you can request information from
              the responsible party about the following information: <br />
              a) the purposes for which the personal data is being processed;
              <br />
              b) the categories of personal data being processed;
              <br />
              c) the recipients or categories of recipients to whom the personal
              data concerning you have been or will be disclosed;
              <br />
              d) the planned duration of storage of the personal data concerning
              you or, if specific information is not possible, criteria for
              determining the storage period;
              <br />
              e) the existence of a right to rectification or erasure of the
              personal data concerning you, a right to restriction of processing
              by the responsible party or a right to object to such processing;
              <br />
              f) the existence of a right to lodge a complaint with a
              supervisory authority;
              <br />
              g) all available information about the origin of the data if the
              personal data is not collected from the data subject;
              <br />
              h) the existence of automated decision-making, including
              profiling, pursuant to Art. 22 (1) and (4) GDPR and - at least in
              these cases - meaningful information about the logic involved, as
              well as the scope and intended effects of such processing for the
              data subject.
              <br />
              You have the right to request information as to whether the
              personal data concerning you will be transferred to a third
              country or to an international organization. In this context, you
              can request to be informed of the appropriate guarantees in
              accordance with Art. 46 GDPR in connection with the transfer.
            </p>
            <h6>2. Right to rectification</h6>
            <p>
              You have the right to have your data rectified and/or completed by
              the controller if the personal data concerning you that are
              processed are incorrect or incomplete. The controller must carry
              out the rectification immediately.
            </p>
            <h6>3. Right to restriction of processing</h6>
            <p>
              You can request the restriction of the processing of personal data
              concerning you under the following conditions: <br />
              a. if you dispute the accuracy of the personal data concerning you
              for a period that enables the controller to verify the accuracy of
              the personal data;
              <br />
              b. the processing is unlawful and you refuse to delete the
              personal data and instead request the restriction of the use of
              the personal data;
              <br />
              c. the controller no longer needs the personal data for the
              purposes of processing, but you need them to assert, exercise or
              defend legal claims, or
              <br />
              d. if you have objected to the processing in accordance with Art.
              21 Para. 1 GDPR and it has not yet been determined whether the
              legitimate reasons of the controller outweigh your reasons.
              <br />
              If the processing of personal data concerning you has been
              restricted, this data may - apart from its storage - only be
              processed with your consent or for the establishment, exercise or
              defense of legal claims or to protect the rights of another
              natural or legal person or for reasons of important public
              interest of the Union or a Member State. If the restriction of
              processing has been restricted in accordance with the
              above-mentioned conditions, you will be informed by the controller
              before the restriction is lifted
            </p>
            <h6>4. Right to deletion</h6>
            <strong>a. Obligation to delete</strong> <br />
            <p>
              You can request that the responsible party delete the personal
              data concerning you immediately, and the responsible party is
              obliged to delete this data immediately if one of the following
              reasons applies: 1. The personal data concerning you are no longer
              necessary for the purposes for which they were collected or
              otherwise processed.
              <br />
              2. You withdraw your consent on which the processing was based in
              accordance with Art. 6 (1)(a) or Art. 9 (2)(a) GDPR, and there is
              no other legal basis for the processing.
              <br />
              3. You object to the processing in accordance with Art. 21 (1)
              GDPR and there are no overriding legitimate reasons for the
              processing, or you object to the processing in accordance with
              Art. 21 (2) GDPR.
              <br />
              4. The personal data concerning you were processed unlawfully.
              <br />
              5. The deletion of the personal data concerning you is necessary
              to fulfill a legal obligation under Union law or the law of the
              Member States to which the controller is subject.
              <br />
              6. The personal data concerning you were collected in relation to
              information society services offered in accordance with Art. 8 (1)
              GDPR.
            </p>
            <strong>b. Information to third parties</strong>
            <br />
            <p>
              If the controller has made the personal data concerning you public
              and is obliged to delete them in accordance with Art. 17 (1) GDPR,
              he shall take appropriate measures, including technical ones,
              taking into account the available technology and the
              implementation costs, to inform data controllers which process the
              personal data that you, as the data subject, have requested that
              they delete all links to these personal data or copies or
              replications of these personal data.
            </p>
            <strong>c. Exceptions</strong>
            <br />
            <p>
              The right to erasure does not apply if processing is necessary{" "}
              <br />
              1. to exercise the right to freedom of expression and information;
              <br />
              2. to fulfil a legal obligation which requires processing under
              Union or Member State law to which the controller is subject, or
              to carry out a task carried out in the public interest or in the
              exercise of official authority vested in the controller;
              <br />
              3. for reasons of public interest in the area of ​​public health
              pursuant to Art. 9 (2)(h) and (i) and Art. 9 (3) GDPR;
              <br />
              4. for archiving purposes in the public interest, scientific or
              historical research purposes or statistical purposes pursuant to
              Art. 89 (1) GDPR, insofar as the right referred to in section a)
              is likely to make the realisation of the objectives of this
              processing impossible or seriously compromises it, or
              <br />
              5. to assert, exercise or defend legal claims.
            </p>
            <h6>5. Right to information</h6>
            <p>
              If you have asserted your right to rectification, erasure or
              restriction of processing vis-à-vis the responsible party, the
              responsible party is obliged to inform all recipients to whom the
              personal data concerning you was disclosed of said rectification,
              erasure or restriction of processing, unless doing so should prove
              impossible or involve disproportionate expenditure. You have the
              right to be informed by the responsible party of these recipients.
            </p>
            <h6>6. Right to data portability</h6>
            <p>
              You have the right to receive the personal data concerning you
              that you have made available to the controller in a structured,
              common and machine-readable format. In addition, you have the
              right to transmit this data to another controller without
              hindrance from the controller to whom the personal data was made
              available, provided that <br />
              1. the processing is based on consent in accordance with Art. 6
              Para. 1 lit. a GDPR or Art. 9 Para. 2 lit. a GDPR or on a contract
              in accordance with Art. 6 Para. 1 lit. b GDPR and
              <br />
              2. the processing is carried out using automated procedures. In
              exercising this right, you also have the right to have the
              personal data concerning you transmitted directly from one
              controller to another controller, insofar as this is technically
              feasible. The freedoms and rights of other persons must not be
              impaired by this. The right to data portability does not apply to
              the processing of personal data necessary for the performance of a
              task carried out in the public interest or in the exercise of
              official authority vested in the controller.
            </p>
            <h6>7. Right of objection</h6>
            <p>
              You have the right to object at any time to the processing of
              personal data concerning you based on Art. 6 (1) (e) or (f) GDPR
              for reasons related to your particular situation; this also
              applies to profiling based on these provisions. The controller
              will no longer process the personal data concerning you unless it
              can demonstrate compelling legitimate grounds for the processing
              which outweigh your interests, rights and freedoms, or the
              processing serves to assert, exercise or defend legal claims. If
              the personal data concerning you is processed in order to conduct
              direct advertising, you have the right to object at any time to
              the processing of the personal data concerning you for the purpose
              of such advertising; this also applies to profiling insofar as it
              is related to such direct advertising. If you object to processing
              for direct marketing purposes, the personal data concerning you
              will no longer be processed for these purposes. In connection with
              the use of information society services, you have the option of
              exercising your right of objection by means of automated
              procedures that use technical specifications, notwithstanding
              Directive 2002/58/EC.
            </p>
            <h6>
              8. Right to revoke the declaration of consent under data
              protection law
            </h6>
            <p>
              You have the right to revoke your declaration of consent under
              data protection law at any time. The revocation of the consent
              does not affect the legality of the processing carried out on the
              basis of the consent until the revocation.
            </p>
            <h6>
              9. Automated decision in individual cases including profiling
            </h6>
            <p>
              You have the right not to be subjected to a decision based
              exclusively on automated processing - including profiling - that
              has legal consequences for you or significantly affects you in a
              similar way. This does not apply if the decision <br />
              a. is necessary for the conclusion or fulfillment of a contract
              between you and the controller,
              <br />
              b. is permitted by Union or Member State law to which the
              controller is subject and this law contains appropriate measures
              to safeguard your rights and freedoms as well as your legitimate
              interests or
              <br />
              c. is made with your express consent.
              <br />
              However, these decisions must not be based on special categories
              of personal data pursuant to Art. 9 (1) GDPR, unless Art. 9 (2)(a)
              or (g) GDPR applies and appropriate measures to protect your
              rights and freedoms as well as your legitimate interests have been
              taken. With regard to the cases referred to in (1.) and (3.), the
              controller shall take appropriate measures to safeguard your
              rights and freedoms as well as your legitimate interests,
              including at least the right to obtain human intervention on the
              part of the controller, to express your point of view and to
              contest the decision.
            </p>
            <h6>10. Right to lodge a complaint with a supervisory authority</h6>
            <p>
              Without prejudice to any other administrative or judicial remedy,
              you have the right to lodge a complaint with a supervisory
              authority, in particular in the Member State of your residence,
              place of work or place of the alleged infringement, if you believe
              that the processing of personal data concerning you violates the
              GDPR. The supervisory authority to which the complaint was
              submitted will inform the complainant of the status and results of
              the complaint, including the possibility of a judicial remedy
              under Art. 78 GDPR. The supervisory authority responsible for this
              is: <br />
              State Commissioner for Data Protection and Freedom of Information
              North Rhine-Westphalia
              <br />
              PO Box 200444
              <br />
              40102 Düsseldorf
              <br />
              Telephone switchboard: +49 (0)211 / 38424 - 0
            </p>
          </Wrap>
        )}
      </div>
    </section>
  );
}
