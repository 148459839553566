export const menu = [
    {
        title: "Advertisers",
        path: "/advertisers"
    },
    {
        title: "Publishers",
        path: "/publishers"
    },
    {
        title: "Contact",
        path: "/contact"
    }
]