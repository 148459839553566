import Yup from './validate'

export const ContactSchema = Yup.object().shape({
    name: Yup.string().required(),
    subject: Yup.string().required(),
    email: Yup.string().email().required(),
    message: Yup.string().required(),
    recaptcha: Yup.string()
        .min(1, 'Prove You Are Not A Robot')
        .required('Prove You Are Not A Robot')
})