import { Link } from "react-router-dom";

export default function Informations({ alias }) {
    return (
        <div className={`contact-info-wrapper contact-col ${alias}`}>
            <div>
                <div className="location-content">
                    <h3> Send us a care package </h3>
                    <div>
                        <p className="infos-title">Corporate headquarters</p>
                        <p className="info-text">{alias === "admatic" ? 'Germany' : 'Turkey'}</p>
                        <p className="info-text">{alias === "admatic" ? 'HangarOne Butzweilerhofallee 3' : 'İçerenköy Mah. Çayır Cad. No:7 Üçgen Plaza Kat:11 Daire 12'}</p>
                        <p className="info-text">{alias === "admatic" ? 'Cologne 017ResCo-work78' : 'Ataşehir, Istanbul'}</p>
                        <p className="info-text">{alias === "admatic" ? '' : '0216 472 54 52'}</p>
                        {alias !== "admatic" && <Link target="_blank" to={`https://maps.app.goo.gl/ndKbbHUTaHwYTPks8`} className="info-link">
                            <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" fill="currentColor" viewBox="0 0 384 512">
                                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                            </svg>
                            <span>Map and directions</span>
                        </Link>}
                    </div>
                </div>
                <div className="social-links">
                    {/*<ul>
                        <li>
                            <a href="#">
                                <span>
                                    <svg fill="currentColor" height="24px" width="24px">
                                        <path d="M10 21v-8.2H7.3V9.6h2.9V7.2A3.9 3.9 0 0114.3 3l2.5.1V6H15c-1.4 0-1.7.6-1.7 1.5v2h3.3l-.5 3.3h-2.8V21h-3.3z" />
                                    </svg>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span>
                                    <svg fill="currentColor" height="24px" width="24px">
                                        <path d="M19 8.4A10.2 10.2 0 018.9 19.1h-.1c-2 0-4-.5-5.5-1.6A7.4 7.4 0 008.6 16c-1.6 0-3-1-3.4-2.5a3.5 3.5 0 001.6 0A3.6 3.6 0 014 9.9c.5.3 1 .4 1.6.4a3.6 3.6 0 01-1.1-4.7C6.3 7.8 9 9.2 11.9 9.3l-.1-.8A3.6 3.6 0 0118 6c.8-.1 1.6-.4 2.3-.8-.3.8-.9 1.5-1.6 2 .7-.1 1.4-.3 2-.6-.4.7-1 1.3-1.7 1.8"></path>
                                    </svg>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span>
                                    <svg fill="currentColor" height="24px" width="24px">
                                        <path d="M7.6 20H4.3V9.3h3.3V20zm.2-14A2 2 0 004 6c0 1 .8 1.9 2 1.9 1 0 1.8-1 1.8-2M20 20h-3.3v-5.2c0-1.2 0-2.8-1.7-2.8-1.8 0-2 1.3-2 2.7V20H9.7V9.3h3.1v1.5A3.5 3.5 0 0116 9c3.4 0 4 2.3 4 5.1V20z"></path>
                                    </svg>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span>
                                    <svg fill="currentColor" height="24px" width="24px">
                                        <path d="M8.3 21c-.8 0-1.5-.2-2.2-.5-.6-.2-1.1-.6-1.6-1-.4-.5-.8-1-1-1.6-.3-.7-.4-1.4-.4-2.2a63.4 63.4 0 01.4-9.6c.2-.6.6-1.1 1-1.6.5-.4 1-.8 1.6-1 .7-.3 1.4-.4 2.2-.4a63.3 63.3 0 019.6.4c.6.2 1.1.6 1.6 1 .4.5.8 1 1 1.6.3.7.4 1.4.4 2.2a63.4 63.4 0 01-.4 9.6c-.2.6-.6 1.1-1 1.6-.5.5-1 .8-1.6 1-.7.3-1.4.4-2.2.5H8.3zm0-16.3a5 5 0 00-1.6.3c-.4.1-.8.4-1 .7-.3.2-.6.6-.7 1a63.2 63.2 0 000 10.6c.1.4.4.8.7 1 .3.3.6.6 1 .7a61.5 61.5 0 0010.6 0c.4-.1.8-.4 1-.7.3-.2.6-.6.7-1a63.5 63.5 0 000-10.6c-.1-.4-.4-.8-.7-1-.2-.3-.6-.6-1-.7a61.6 61.6 0 00-9-.3zm-1 7.3c0-2.6 2.1-4.6 4.7-4.6a4.6 4.6 0 110 9.2c-2.6 0-4.6-2-4.6-4.6zM9 12a3 3 0 106 0 3 3 0 00-6 0zm6.7-4.8c0-.6.5-1 1.1-1a1 1 0 11-1 1z"></path>
                                    </svg>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span>
                                    <svg fill="currentColor" height="24px" width="24px">
                                        <path d="M22.9 6.5c-.3-1-1-1.8-2-2C19 4 12 4 12 4s-7.1 0-8.9.5c-1 .2-1.7 1-2 2-.6 3.6-.6 7.4 0 11 .3 1 1 1.8 2 2C5 20 12 20 12 20s7.1 0 8.9-.5c1-.2 1.7-1 2-2 .6-3.6.6-7.4 0-11zM9.7 15.4V8.6l6 3.4-6 3.4z"></path>
                                    </svg>
                                </span>
                            </a>
                        </li>
    </ul>*/}
                </div>
            </div>
        </div>
    )
}