import { useContext } from "react";
import { AliasContext } from "../../components/context/AliasContext";
import Wrap from "../../components/wrap";
import { Link } from "react-router-dom";

export default function Imprint() {
  const { alias } = useContext(AliasContext);

  return (
    <section className="privacy-policy">
      <div>
        {alias === "admatic" && (
          <Wrap>
            <h3>IMPRINT</h3>
            <p>
              Information on the responsible party <br />
              Admatic GmbH
              <br />
              Butzweilerhofallee 3<br />
              50829 Cologne
              <br />
              Germany
            </p>
            <h5>Represented by:</h5>
            <p>
              Managing Director: Tansel Okan <br />
              Commercial Register: HRB 116994
              <br />
              Registration Court: District Court of Cologne
            </p>
            <h5>VAT ID</h5>
            <p>
              VAT identification number according to Section 27a of the Sales
              Tax Law: DE368343824
            </p>
            <h5>Contact</h5>
            <p>E-mail info@admatic.de</p>
            <h5>Liability</h5>
            <p>
              We are responsible for the content of our website in accordance
              with the provisions of general law, in particular Section 7
              Paragraph 1 of the Telemedia Act. All content is created with due
              care and to the best of our knowledge and is for purely
              informational purposes only. Insofar as we refer to third-party
              websites on our website via hyperlink, we cannot guarantee that
              the linked content is always up-to-date, correct and complete, as
              this content is outside our area of ​​responsibility and we have
              no influence on its future design. If you believe that any content
              violates applicable law or is inappropriate, please let us know.
            </p>
            <h5>Copyright notice</h5>
            <p>
              The texts, images, photos, videos or graphics on our website are
              generally protected by copyright. Any unauthorized use (in
              particular the reproduction, processing or distribution) of this
              copyrighted content is therefore prohibited. If you intend to use
              this content or parts of it, please contact us in advance using
              the details above. If we are not the owner of the required
              copyright usage rights ourselves, we will endeavor to put you in
              touch with the authorized party.
            </p>
          </Wrap>
        )}
      </div>
    </section>
  );
}
