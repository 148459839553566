import React, { useContext, useState } from "react";
import { AliasContext } from "../../components/context/AliasContext";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

export default function Optout() {
  const { alias, cookiePermission, setCookiePermission } = useContext(AliasContext)

  
  const toggleCookiePermission = () => {
    const newPermission = cookiePermission === '1' ? '0' : '1';
    setCookiePermission(newPermission);
    Cookies.set('adm_cookie_permission', newPermission, { expires: 365 });
  };

  return (
    <div className="single-page">
      <div className="w-3/4 p-4">
        <p>
          Opting-Out from Targeted Advertisements and Tracking on Websites You
          can opt-out from tracking and AdMatic’s unique cookie identifiers
          here. To do so, click the button at your right once and we will take
          all necessary means to ensure that no tracking is being applied.
        </p>
        <p>Please be aware that:</p>
        <p>
          1. AdMatic needs to set an anonymous “opt-out” cookie to indicate your
          decision not to be tracked. Your opt-out will remain in effect only as
          long as this cookie is present in your browser and accessible to
          AdMatic.
        </p>
        <p>
          2. Cookies are browser and device-specific – opting out of tracking on
          this browser and device does not affect your settings on a secondary
          device and/or browser.
        </p>
        <p>
          3. Some browsers (e.g. Safari) limit cookie functionalities by default
          – in consequence, your opt-out may not work as intended.
        </p>
        <p>
          4. When you opt-out, any data we hold will be disassociated from your
          browser and device and not used for ad targeting anymore. You will
          still continue to see advertising – it may just be less relevant and
          useful to you than before.
        </p>
        <p>
          Opting-Out from Advertising and Tracking in Mobile Applications For
          opt-out of ad tracking and targeting on many mobile devices such as
          iPhones, iPads, Android devices, TV streaming devices, or other Smart
          TV devices, you should preferably consult your device settings and
          instructions provided by the device manufacturer. Below is exemplary
          information solely for informational purposes for which we disclaim
          any warranty or representation that the opt-out methods will work or
          that they are most current.
        </p>
        <p>
          1. Apple iOS Example Opt-out of targeted advertising: open “Settings”,
          tap on “Privacy”, then “Advertising”, and set Limit Ad Tracking to
          “on”.
        </p>
        <p>
          To reset your Advertising Identifier on iOS, open “Settings”, tap
          “Privacy”, tap “Advertising”, and tap “Reset Advertising Identifier”.
        </p>
        <p>
          2. Android Example Open the Google Settings app, select Ads then
          “Reset advertising ID” and choose “Opt-out of interest-based ads”.
        </p>
        <p>
          You may also choose to opt-out from interest-based advertising via the
          Network Advertising Initiative
        </p>
        <p>
          For detailed information on our cookies usage purposes and for general
          commitment towards our platform and services privacy, see{" "}
          <Link to={alias == "admatic" ? "/privacy-policy" : "/user-agreement"}>
            AdMatic Privacy Policy
          </Link>
          .
        </p>
      </div>

      <div className="w-1/4 p-4 flex items-center justify-center">
        <button className="bg-red-500 text-white py-2 px-4 rounded" onClick={toggleCookiePermission}>
            {cookiePermission === "1" ? 'Opted-in' : 'Opted-out'}
        </button>
      </div>
    </div>
  );
}
