import contactBg from '../../../assets/imgs/contact.jpeg'

export default function ContactHeader({alias}) {
    return (
        <div className="contact-header">
            <div className="contact-header-bg">
                <img src={contactBg} />
            </div>

            <div className="contact-header-wrapper">
                <h2 className={`contact-title ${alias}`}>Get in touch</h2>
                <p className="contact-desc">Contact us, we're happy to assist you any time!</p>
            </div>
        </div>
    )
}