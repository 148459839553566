import { useRef, useState } from "react"
import { ContactAPI } from "../../../api"
import { Field, Form, Formik } from "formik"
import { ContactSchema } from "../../../validation"
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactForm({alias}) {

    const [serverResponse, setServerResponse] = useState(null)

    const reCaptchaRef = useRef(null);

    const onSubmit = async (values, actions) => {
        try {
            const response = await ContactAPI(values.name, values.subject, values.email, values.message)
            setServerResponse(response)
            actions.resetForm()
            if (reCaptchaRef.current) {
                reCaptchaRef.current.reset();
            }
            actions.setSubmitting(false)
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <div className="contact-col">
            <div className="contact-form">
                <h2>Send us a message</h2>
                <Formik
                    initialValues={{ name: '', subject: '', email: '', message: '', recaptcha: '' }}
                    validationSchema={ContactSchema}
                    onSubmit={(values, actions) => onSubmit(values, actions)}
                >
                    {({ handleChange, values, errors, touched, isSubmitting, isValid, dirty, setFieldValue }) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="name">Name {errors.name && touched.name && <span className="error-message">{errors.name}</span>}</label>
                                <div className="field-wrapper">
                                    <Field name="name" placeholder="Enter your name" id="name" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="subject">Subject {errors.subject && touched.subject && <span className="error-message">{errors.subject}</span>}</label>
                                <div className="field-wrapper selectbox">
                                    {!values.subject && <span className="select-placeholder">Choose subject</span>}
                                    <Field as="select" name="subject" id="subject" onChange={handleChange}>
                                        <option disabled></option>
                                        <option value="Become a publisher">Become a publisher</option>
                                        <option value="Become an advertiser">Become an advertiser</option>
                                        <option value="Other">Other</option>
                                    </Field>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email {errors.email && touched.email && <span className="error-message">{errors.email}</span>}</label>
                                <div className="field-wrapper">
                                    <Field name="email" type="mail" placeholder="Enter your email" id="email" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message {errors.message && touched.message && <span className="error-message">{errors.message}</span>}</label>
                                <div className="field-wrapper">
                                    <Field
                                        as="textarea"
                                        id="message"
                                        name="message"
                                        rows="4"
                                        placeholder="Enter your message… (10 character minimum)"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                {errors.recaptcha && touched.recaptcha && <label><span className="error-message">{errors.recaptcha}</span></label>}
                                <ReCAPTCHA
                                    sitekey="6LcW0UkpAAAAAMM4LCCRNJf3QzP80Hbaia6-FBF3"
                                    ref={reCaptchaRef}
                                    name="recaptcha"
                                    id="recaptcha"
                                    value={values.recaptcha}
                                    onChange={async () => {
                                        const token = await reCaptchaRef.current.getValue();
                                        setFieldValue('recaptcha', token);
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                {serverResponse && <label className={`message-container ${ serverResponse?.statusCode === 200 ? 'success' : 'error'}`}><span className={`${ serverResponse?.statusCode === 200 ? 'success-message' : 'error-message'}`}>{serverResponse.message}</span></label>}
                            </div>
                            <div className="form-group">
                                <button disabled={!isValid || !dirty || isSubmitting} type="submit" className={`button-primary ${alias}`}>Submit</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}